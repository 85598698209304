import Vue from 'vue';
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Bitrix24 from './plugins/vue-bitrix24';
import '@/styles/styles.scss'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Notifications from 'vue-notification'

import Autocomplete from 'v-autocomplete' 
import 'v-autocomplete/dist/v-autocomplete.css'
 
Vue.use(Autocomplete)  
Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Bitrix24);
Vue.use(Notifications)
  
Vue.config.productionTip = false
Vue.config.devtools = true;
Vue.config.debug = true;
Vue.config.silent = true;
 

Bitrix24.init().then(($BX24) => {
  new Vue({
    router,
    store,
    provide: { $BX24 },
    render: (h) => h(App),
  }).$mount('#app');
}); 