import * as requests from '@/api/requests.js';
import axios from 'axios'

export default {
	namespaced: true,
	state: {
		data:null, 
		link_pdf:null,
		convert_status:false,
	},
	getters: {
		views:state=>{
			return state
		},  
	},
	mutations: { 
		setLinkPdf(state, link){ 
			state.link_pdf = link; 
		},  
		setConvertStatus(state, status){ 
			state.convert_status = status; 
		},  
		setViews(state, data){ 
			state.data = data; 
		},  
	},
	actions: {
		async load({ commit }){
			let res = await requests.viewsGet();		 
			commit('setViews', res.template);
		}, 
		async convertPdf({ commit, rootGetters }){
			commit('setLinkPdf', null);
			commit('setConvertStatus', true);
			 
			let calculations = rootGetters['kpComputed/kpComputed'].calculations;
			let deal = rootGetters['kpComputed/kpComputed'].deal;
			let deal_id = rootGetters['kpComputed/kpComputed'].deal.id;
			let sketch = rootGetters['kpComputed/kpComputed'].sketch;
			 

			await requests.convert_PDF({deal_id, data:{calculations, deal, sketch}}).then(async(res)=>{
				console.log(res)
				if(res.result){ 
					 
					commit('setLinkPdf', res.link);
					commit('setConvertStatus', false);
					// let headers = {
					// 	mode: 'cors',
					// 	cache: 'no-cache'
					//   }
					//   try {
					// 	let result = await fetch("https://apikp.pzn.su/"+res.link, headers);
					// 	console.log(result);
					//   } catch (e) {
					// 	throw new Error(e)
					//   }

						// axios({
						// 	url: "https://apikp.pzn.su/"+res.link,
						// 	method: 'GET',
						// 	withCredentials: true,
						// 	headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
						// 	responseType: 'blob',
						// }).then((response) => {
						// 	var fileURL = window.URL.createObjectURL(new Blob([response.data]));
						// 	var fileLink = document.createElement('a');
		
						// 	fileLink.href = fileURL;
						// 	fileLink.setAttribute('download', 'file.pdf');
						// 	document.body.appendChild(fileLink);
		
						// 	fileLink.click();
						// }); 
				}
			});		 
		}, 
	}
}