import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Bitrix24 from './../plugins/vue-bitrix24';

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/Index.vue'),
  },
  {
    path: '/calculator',
    name: 'calculator',
    component: () => import('@/views/Сalculator.vue'),
  }, 
  {
    path: '/accessclosed',
    name: 'access-closed',
    component: () => import('@/views/AccessClosed.vue'),
  },  
  {
    path: '/kp',
    name: 'kp',
    component: () => import('@/views/Kp.vue'),
    children:[
      {
        name: 'kp-computed',
        path: '',
        component: () => import('@/views/KpComputed.vue'), 
      },
      {
        name: 'kp-views',
        path: 'views',
        component: () => import('@/views/KpViews.vue'), 
      },
      {
        name: 'kp-cost-price',
        path: 'costprice',
        component: () => import('@/views/KpCostPrice.vue'), 
      },
      {
        name: 'kp-template',
        path: 'template',
        component: () => import('@/views/KpTemplate.vue'), 
      }
    ]
  }, 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

Bitrix24.init().then(($BX24) => {
  router.beforeEach((to, from, next) => {
 
    if(to.name == 'access-closed') return next();

    if(to.name == 'calculator') return next();
    else{ 

      if($BX24 && 
        Object.keys($BX24).length === 0 && 
        Object.getPrototypeOf($BX24) === Object.prototype
        ) return next({name:"access-closed"});
      else {
  
        $BX24.batch({
          info: ['app.info'],
          profile: ['profile'],
        }).then((res)=>{
          store.dispatch("account/setAdmin", res.profile.ADMIN);
        });

        if(to.name == 'index') return next({name:"kp-computed"});
        else return next();
      } 
    }
    
  })
});

export default router
