export default {
	namespaced: true,
	state: {
		admin:false,		 
	},
	getters: {
		account:state=>{
			return state
		},  
	},
	mutations: { 
		setAdmin(state, status){ 
			state.admin = status; 
		},  
	},  
	actions: {
		async setAdmin({ commit }, status){ 
			commit('setAdmin', status);
		}, 
	}
}