import * as requests from '@/api/requests.js';

export default {
	namespaced: true,
	state: {
		city_list:null,
		city_selected:null,		 
		street_list:null,
		street_selected:null,
		home_selected:null,
		delivery:null,
		delivery_date:null,
		status_calc:false,
		errors:null,		 
	},
	getters: {
		delLine:state=>{
			return state
		},  
	},
	mutations: { 
		setCityList(state, list){ 
			state.city_list = list; 
		},  
		setStreetList(state, list){ 
			state.street_list = list; 
		},  
		nullableStreet(state){
			state.street_selected = null;
		},
		setErrors(state, errors){
			state.errors = errors;
		},
		setDelivery(state, {delivery, delivery_date}){
			state.delivery = delivery;
			state.delivery_date = delivery_date;
		},
		setStatusCalc(state, status){
			state.status_calc = status;
		} 		 
	},
	actions: {
		async changeEnterCity({ commit }, query_city){ 
			let res = await requests.cityListGet({query_city});		 
			commit('setCityList', res.list);
		}, 
		async changeEnterStreet({ commit, state }, query_street){ 
			let res = await requests.streetListGet({query_street, city:state.city_selected});		 
			commit('setStreetList', res.list);
		}, 
		selectedCity({ commit }){
			commit('nullableStreet');
		},
		async calcDelivery({ commit, state, rootGetters, dispatch }){ 
			
			let totalWeight = 0;

			commit('setStatusCalc', true);	
			commit('setErrors', null);			
			commit('setDelivery', { delivery:null, delivery_date:null });

			if(rootGetters["kpComputed/kpComputed"].calculations.total_weight > 1) totalWeight = rootGetters["kpComputed/kpComputed"].calculations.total_weight;
			else
				if(rootGetters["kpComputed/kpComputed"].calculations.mechanics !== null)
					for(let key in rootGetters["kpComputed/kpComputed"].calculations.mechanics)
						if(rootGetters["kpComputed/kpComputed"].calculations.mechanics[key].mechanic !== null) totalWeight = 30;   
 
			
			let street = state.street_selected.code;
			let res = await requests.calcDelivery({totalWeight, street});	
			if(res.result) {
				commit('setDelivery', { delivery:res.delivery, delivery_date:res.date });   
				await dispatch("kpComputed/changeDelivery", res.delivery.price, {root:true}) 
			}
			else commit('setErrors', res.errors);  
			console.log(res);

			commit('setStatusCalc', false);	
		} 
	}
}