import * as requests from '@/api/requests.js';

export default {
	namespaced: true,
	state: {
		data:null,
		success_up:false,
	},
	getters: {
		template:state=>{
			return state
		},  
	},
	mutations: { 
		setTemplate(state, data){ 
			state.data = data; 
		}, 
		setSuccessUp(state, status){ 
			state.success_up = status; 
		},   
	},
	actions: {
		async load({ commit }){
			let res = await requests.templateGet();		 
			commit('setTemplate', res.template);
		},
		async save({ commit, state, dispatch }){
			commit('setSuccessUp', false);
			let res = await requests.templateUp(state.data);	
			if(res.result){	
				await dispatch("load");
				commit('setSuccessUp', true); 
			}
		},
		async handleQr({ dispatch }, file){			 
			let formData = new FormData(); 
			formData.append('file', file);
			let res = await requests.templateQr(formData);
			if(res.result) await dispatch("load");
		}
	}
}