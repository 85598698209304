import * as requests from '@/api/requests.js';

export default {
	namespaced: true,
	state: {
		status_send_form:false,
		status_view_form:false,
		products:[],
		total_weight:0,
		total_price:0,
		total_products_cost_price:0,
		total_products_tax_before:0,
		total_price_tax_before:0,
		total_products_tax_after:0,
		total_price_tax_after:0,
		insurance:null,
		insurance_tax_before:0, 
		insurance_tax_after:0, 
		koef_weight_1:0,
		view_total_products_price_all:0,
		products_all:[],  
		cloths_all:[],
		extra_charge:null,

		form:{
			name:null,
			email:null,
			phone:null,
			comment:null
		}
	},
	getters: {
		calculator:state=>{
			return state
		},  
	},
	mutations: { 
		setList(state, data){ 
			state.products_all = data.products_all; 
			state.cloths_all = data.cloths_all;  
			state.extra_charge = data.extra_charge;
			state.insurance = data.insurance; 
		},
		addProduct(state){
			state.products.push({
				product:null,
				quantity:1,
				cloth:null,
				height:0,
				width:0,
				fold:1,				
				lining:false,
				cloth_lining:null,
				weight:0,

				job_price:0,
				total_product_pg:0,
				total_lining_pg:0,

				total_price:0,
				tax_before:0,
				tax_after:0, 

				koef_weight_1:0,
				koef_weight_block_product_1:0,

				view_total_price_1:0,
				view_total_price_all:0,
			})
		},
		spliceProduct(state, key){
			state.products.splice(key, 1)
		}, 
		setTotalProductPg(state, { key, total_product_pg }){ 
			state.products[key].total_product_pg = total_product_pg;
		},
		setTotalLiningPg(state, { key, total_lining_pg }){ 
			state.products[key].total_lining_pg = total_lining_pg;
		},
		setWeight(state, { key, weight }){ 
			if(key !== null) state.products[key].weight = weight; 
			let total_weight = 0; 
			if(state.products.length > 0){ 
				for(let key in state.products)
					total_weight = total_weight + state.products[key].weight;   
			}
			state.total_weight = Math.round(total_weight);
		},
		setTotalProductPrice(state, { key, total_price }){   
			state.products[key].total_price = total_price;  
		}, 
		setJobProductPrice(state, { key, job_price_total }){ 
			state.products[key].job_price = job_price_total;
		}, 
		setTotalPrice(state){

			// себестоимость изделий
			let total_products_cost_price = 0; 
			if(state.products.length > 0){ 
				for(let key in state.products)  
					total_products_cost_price = total_products_cost_price + state.products[key].total_price; 				 
			}
			state.total_products_cost_price = total_products_cost_price;

			// до налога
			let total_products_tax_before = 0;

			if(state.products.length > 0){
				for(let key in state.products){
					let total_price = state.products[key].total_price; 
					let tax_before = total_price+((total_price*state.extra_charge)/100);
					total_products_tax_before = total_products_tax_before + tax_before;
					state.products[key].tax_before = tax_before;
				}    
			}
			state.total_products_tax_before = total_products_tax_before; 
			state.insurance_tax_before = state.insurance+((state.insurance*state.extra_charge)/100);
			state.total_price_tax_before = state.insurance_tax_before + total_products_tax_before
			 
 
			// после налога
			//УСН
			if(total_products_cost_price !==0) { 	

				let total_products_tax_after = 0;

				if(state.products.length > 0){
					for(let key in state.products){
						let tax_before = state.products[key].tax_before; 
						let tax_after = tax_before+((tax_before/93)*7);
						total_products_tax_after = total_products_tax_after + tax_after;
						state.products[key].tax_after = tax_after;
					}    
				}
				state.total_products_tax_after = total_products_tax_after; 
				state.insurance_tax_after = state.insurance_tax_before+((state.insurance_tax_before/93)*7);
				state.total_price_tax_after = state.insurance_tax_after + total_products_tax_after;  
			}

			/////

			state.koef_weight_1 = state.total_products_tax_after;  
	
			if(state.products.length > 0)
				for(let key in state.products){ 
					state.products[key].koef_weight_1  = state.products[key].tax_after/state.koef_weight_1;  
					state.products[key].koef_weight_block_product_1 = state.products[key].tax_after/state.total_products_tax_after; 
				}
				
			  
			//V
			let view_total_products_price_all = 0;

			if(state.products.length > 0){
				for(let key in state.products){ 
					state.products[key].view_total_price_all = Math.ceil(state.products[key].tax_after + 
						state.insurance_tax_after *
						state.products[key].koef_weight_block_product_1 +
						(0 + 0)*
						state.products[key].koef_weight_1);
							
					state.products[key].view_total_price_1 = Math.round(state.products[key].view_total_price_all/state.products[key].quantity);  

					view_total_products_price_all = view_total_products_price_all + state.products[key].view_total_price_all;
				}
				state.view_total_products_price_all = view_total_products_price_all;
			}
				  
			state.total_price = view_total_products_price_all;


			if(state.products.length > 0)
				for(let key in state.products)
					if(state.products[key].view_total_price_1 !== 0){
						let view_total_price_all = state.products[key].view_total_price_1*state.products[key].quantity;
						let incriment = view_total_price_all - state.products[key].view_total_price_all;
						state.products[key].view_total_price_all = view_total_price_all;
						state.view_total_products_price_all = state.view_total_products_price_all + incriment;
						state.total_price = state.total_price + incriment;
					}

		},
		setStatusSendForm(state, status){
			state.status_send_form = status
		},
		setStatusViewdForm(state, status){
			state.status_view_form = status
		}
	},
	actions: {
		async loadList({ commit }){
			let data = await requests.loadListCalc();		 
			commit('setList', data);
		},
		async addProduct({ commit, dispatch }, data){
			await commit('addProduct', data);
			dispatch("sendParentHeight")
		},
		async changeProduct({ state, commit, dispatch }, key){
			 
			let product_pg = null;
			let lining_pg = null;
 
			// вычисление погонных метров лицевой части
			if(
				state.products.length > 0 && 
				state.products[key].cloth !== null &&
				state.products[key].width !== 0 &&
				state.products[key].height !== 0 &&
				state.products[key].fold !== 0 &&
				state.products[key].quantity !== 0 
			) {
				let roll_width = Number(state.products[key].cloth.roll_width);
				await dispatch("calcPgProduct", {key, roll_width, type:'product'}).then((total_product_pg)=>{  
					product_pg = total_product_pg; 
					commit('setTotalProductPg', { key, total_product_pg }); 
				}); 
			}
			else commit('setTotalProductPg', { key, total_product_pg:0 });

			// вычисление погонных метров подкладки
			if(
				state.products.length > 0 &&
				state.products[key].lining &&
				state.products[key].cloth_lining !== null &&
				state.products[key].width !== 0 &&
				state.products[key].height !== 0 &&
				state.products[key].fold !== 0 &&
				state.products[key].quantity !== 0 
			) {
				let roll_width = Number(state.products[key].cloth_lining.roll_width);
				await dispatch("calcPgProduct", { key, roll_width, type:'lining' }).then((total_lining_pg)=>{ 
					lining_pg = total_lining_pg; 
					commit('setTotalLiningPg', { key, total_lining_pg }); 
				}); 
			}
			else commit('setTotalLiningPg', { key, total_lining_pg:0 });

			// вычисление веса
			if(
				state.products.length > 0 && 
				state.products[key].cloth !== null &&
				state.products[key].width !== 0 &&
				state.products[key].height !== 0 && 
				state.products[key].quantity !== 0 
			) dispatch("calcWeightProduct", key);  

			// вычисление стоимости материала
			let total_price = 0;  
			if(product_pg !== null) total_price = product_pg*state.products[key].cloth.price_pm; 
			if(lining_pg !== null) total_price = total_price + lining_pg*state.products[key].cloth_lining.price_pm;

			commit('setTotalProductPrice', { key, total_price });   

			// вычисление стоимости работы и себестоимости изделия
			
			if(
				state.products.length > 0 &&  
				state.products[key].width !== 0 &&
				state.products[key].height !== 0 &&
				state.products[key].fold !== 0 &&
				state.products[key].quantity !== 0 
			) {
				let width = Number(state.products[key].width);
				let height = Number(state.products[key].height);
				let fold = Number(state.products[key].fold);
				let quantity = Number(state.products[key].quantity);
				let job_price = Number(state.products[key].product.job_price);
				let total_price = Number(state.products[key].total_price); 
				let job_price_total = height*width*fold*quantity*job_price;
				if(state.products[key].lining && state.products[key].cloth_lining !== null) job_price_total = 2*job_price_total; 
				total_price = Number(total_price) + Number(job_price_total); 

				commit('setJobProductPrice', { key, job_price_total });  
				commit('setTotalProductPrice', { key, total_price }); 
			} 		  
			////////////////  
			await commit('setTotalPrice');  

			dispatch("sendParentHeight")
		},		
		calcPgProduct({ state }, { key, roll_width, type}){ 
 
			let width = Number(state.products[key].width);
			let height = Number(state.products[key].height);
			let fold = Number(state.products[key].fold);
			let quantity = Number(state.products[key].quantity);
			  
			let total_pg = (width*fold*quantity)/roll_width;

			if(Number.isInteger(total_pg)) total_pg = total_pg + 1;
			else total_pg = Math.ceil(total_pg);

			if(state.products[key].product.id == 2) {//"Арлекин фигурный"
				if(type == 'product' || type == 'lining')total_pg = total_pg*(2*height+(2*height*3/100)) 
			}
			else if(state.products[key].product.id == 7) {//"Задник французский"
				if(type == 'product')total_pg = total_pg*(2*height+(2*height*3/100)) 
			}
			else if(state.products[key].product.id == 8) {//"Задник австрийский"
				if(type == 'product')total_pg = total_pg*(1.3*height+(1.3*height*3/100)) 
			}
			else { 
				if(height <= 3.3) height = height + 0.15;
				if(height > 3.3 && height <= 4.5) height = height + 0.2;
				if(height >= 4.5) height = height + 0.25; 
				total_pg = total_pg*(height+(height*3/100));  
			}			 

			return total_pg; 
		}, 
		calcWeightProduct({ state, commit }, key){ 

			let width = Number(state.products[key].width);
			let height = Number(state.products[key].height);
			let fold = Number(state.products[key].fold);
			let quantity = Number(state.products[key].quantity);
			let fabric_density = Number(state.products[key].cloth.fabric_density);
			let weight = width*height*quantity*fold*fabric_density/1000;

			if(state.products[key].lining && state.products[key].cloth_lining !== null){				
				let fabric_density = Number(state.products[key].cloth_lining.fabric_density);
				weight = weight + (width*height*quantity*fold*fabric_density/1000);
				weight = Math.ceil(weight);
			}
			
			commit('setWeight', { key, weight });
		},
		async delProduct({ commit, dispatch }, key){  
			await commit('spliceProduct', key);  
			commit('setWeight', { key:null, weight:null }); 
			commit('setTotalPrice'); 
			dispatch("sendParentHeight")
		},
		async sendForm({ state, commit, dispatch }){

			let products = [];

			for(let key in state.products){
				products.push({
					product_name:state.products[key].product.name,
					height:state.products[key].height,
					width:state.products[key].width,
					fold:state.products[key].fold,
					quantity:state.products[key].quantity,
					cloth_name:state.products[key].cloth == null ? null : state.products[key].cloth.name,
					cloth_lining_name:state.products[key].lining && state.products[key].cloth_lining !== null ? state.products[key].cloth_lining.name : null,
					weight:state.products[key].weight
				});
			}

			let calculation = {
				products,
				total_weight:state.total_weight,
				total_price:state.total_price
			};
			  
			await requests.calculatorSaveDataForm({
				name:state.form.name,
				email:state.form.email,
				phone:state.form.phone,
				comment:state.form.comment,
				calculation,
			}).then(async (response)=>{
				if(response.result){
					await commit('setStatusViewdForm', false);
					await commit('setStatusSendForm', true);
					dispatch("sendParentHeight")
				}
			});	  
		},
		async setStatusViewdForm({ commit, dispatch }, status){
			await commit('setStatusViewdForm', status);
			dispatch("sendParentHeight")			 
		},
		sendParentHeight(){
			var targetDomain = "https://pzn.su/";
			var message = '{"height": ' + (document.body.scrollHeight+20) + '}';
			parent.postMessage(message, targetDomain);
		}
	}
}