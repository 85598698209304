import * as requests from '@/api/requests.js';

export default {
	namespaced: true,
	state: {
		success_up:false,
		tables:null, 
	},
	getters: {
		costPrice:state=>{
			return state
		},  
	},
	mutations: { 
		setSuccessUp(state, status){ 
			state.success_up = status; 
		},  
		setTables(state, tables){ 
			state.tables = tables; 
		},  
		setCloth(state, cloth){ 
			state.tables.сloth.items = cloth; 
		},  
		pushCloth(state, cloth){ 
			state.tables.сloth.items.push(cloth);
		},
		delCloth(state, index){   
			state.tables.сloth.items.splice(index, 1);
		}
	},
	actions: {
		async loadCostPrice({ commit }){
			let data = await requests.costPriceGet();		 
			commit('setTables', data.tables);
		},
		addCloth({ commit, state }){
			if(state.tables.сloth.items == null) commit('setCloth', [{id:null, name:"Новая ткань", price_pm:0, roll_width:0, fabric_density:0}]);
			else commit('pushCloth', {id:null, name:"Новая ткань", price_pm:0, roll_width:0, fabric_density:0});
		},
		delCloth({ commit, state }, data){
			if(state.tables.сloth.items !== null) commit('delCloth', data.index)
		},
		async costPriceUp({ commit, state }){			 
			commit('setSuccessUp', false);
			await requests.costPriceUp({
				сloth:state.tables.сloth.items, 
				profile:state.tables.profile.items, 
				product:state.tables.product.items, 
				montage:state.tables.montage.items, 
				montage_const:state.tables.montage_const.items, 
				insurance:state.tables.insurance.items, 
				delivery:state.tables.delivery.items, 
				cornice:state.tables.cornice.items
			}); 
			let data = await requests.costPriceGet();		 
			commit('setTables', data.tables);
			commit('setSuccessUp', true);			 
		},
		 
	}
}