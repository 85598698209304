import axios from 'axios';
import store from '../store'

const instance = axios.create({
	baseURL: 'https://apikp.pzn.su/api/',
	timeout: 300000,  
});
 
instance.interceptors.request.use(async config => {
	//console.log(config); 
	//await store.dispatch('other/setPreloader', true);
	return config
})
   
instance.interceptors.response.use(async response => { 
	//await store.dispatch('other/setPreloader', false);
	return response
}) 

export default instance;