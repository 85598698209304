import server from '@/api/http';

export async function costPriceGet(){  
	let { data } = await server.get('kp/cost_price/get');
	return data;
} 
export async function costPriceUp(params){  
	let { data } = await server.post('kp/cost_price/up', {params});
	return data;
}
export async function templateGet(){  
	let { data } = await server.get('kp/template/get');
	return data;
}  
export async function templateUp(params){  
	let { data } = await server.post('kp/template/up', { params });
	return data;
}
export async function templateQr(params){  
	let { data } = await server.post('kp/template/up_file', params); 
	return data;
}  
export async function loadListCalc(){  
	let { data } = await server.get('kp/computed/get/list');
	return data;
}  
export async function loadDataCalc(){  
	let { data } = await server.get('kp/computed/get/data');
	return data;
}  
export async function CalcSave(params){  
	let { data } = await server.post('kp/computed/save', params); 
	return data;
}
export async function viewsGet(params){  
	let { data } = await server.get('kp/views/get', { params }); 
	return data;
}      
export async function sketchSave(params){  
	let { data } = await server.post('kp/computed/sketch/save', params); 
	return data;
}  
export async function sketchDel(params){  
	let { data } = await server.get('kp/computed/sketch/del', { params:params }); 
	return data;
}      
export async function convert_PDF(params){   
	let { data } = await server.post('kp/convert/pdf', params); 
	return data;
}      

export async function cityListGet(params){  
	let { data } = await server.get('delline/city/get', { params:params }); 
	return data;
}  

export async function streetListGet(params){  
	let { data } = await server.get('delline/street/get', { params:params }); 
	return data;
}  
export async function calcDelivery(params){  
	let { data } = await server.get('delline/get', { params:params }); 
	return data;
}  
export async function calculatorSaveDataForm(params){  
	let { data } = await server.get('calculator/data_form/save', { params:params }); 
	return data;
}  
 
 

 