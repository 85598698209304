import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Vuex)
Vue.use(VueAxios, axios)

import costPrice from './costPrice';
import template from './template'; 
import views from './views'; 
import account from './account';
import kpComputed from './kpComputed'; 
import delLine from './delLine'; 
import calculator from './calculator'; 
 
export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    account,
    costPrice,
    template,
    views,
    kpComputed,
    delLine,
    calculator
  }
})
